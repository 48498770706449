<template>
  <v-card>
      <v-card-title class="primary--text text--darken-4 text-h5">แก้ไขข้อมูล<v-spacer/><v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
  <vue-json-editor v-bind:value="value" :show-btns="false" :expandedOnStart="true" @json-change="onJsonChange"  :mode="'code'" ></vue-json-editor>
      </v-card-text>
      <v-card-actions><v-spacer/><v-btn x-large color="primary" @click="$emit('onSave')">บันทึก</v-btn><v-spacer/></v-card-actions>
  </v-card>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object
    }
  },
  components: {
    vueJsonEditor
  },
  methods: {
    onJsonChange (value) {
      this.$emit('change', value)
      console.log('value:', value)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .jsoneditor-vue {
  height: 800px;
}
::v-deep .editor {
    height: 800px;
}
</style>
